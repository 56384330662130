@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800,900);

@import url(https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap);


:root {
    --mantine-color-scheme: 'light' !important;
    --main-color: #4590D5;
    ;
    --base-gray: #000000;
    --off-gray: #f1f1f1;
    --item-gray: #9D9D9D;
    --text: #000000;
    --text-gray: #6B6A69;
    --trans: #ffffff00;
    --px11: 0.6875rem;
    --px12: 0.75rem;
    --px13: 0.813rem;
    --px14: 0.875rem;
    --px15: 0.9375rem;
    --px16: 1rem;
    --px17: 1.0625rem;
    --px18: 1.125rem;
    --px19: 1.1875rem;
    --px20: 1.25rem;
    --px21: 1.3125rem;
    --px22: 1.375rem;
    --px23: 1.4375rem;
    --px24: 1.5rem;
    --px25: 1.5625rem;
    --px26: 1.625rem;
    --px27: 1.6875rem;
    --px28: 1.75rem;
    --px29: 1.8125rem;
    --px30: 1.875rem;
    --titleFont: 2.2rem;

    --hoverBorder: rgba(0, 0, 0, 0.068);
    --frostedBorder: solid 1px rgba(255, 255, 255, 0.113);
    --heroFont: 2.1rem;
    --frostedBackground: rgb(22 22 22);
    ;
    --lightSurface: #1d1d1da1;
    --glassBackground: rgb(255 255 255 / 2%);
    --glassSurface: rgb(255 255 255 / 4%);
    --glassText: rgba(255, 255, 255, 0.633);
    --grayBackground: #f1f3f5;
    --gradientedFrostedBorder: solid 1.5px;
    --frostedText: rgba(255, 255, 255, 0.7);
    --cornerRounding: 30px;
    --iconGray: rgba(106, 105, 106, 1);
    --headerHeight: 80px;
}

body {
    background-color: white;
}

button {
    cursor: pointer;
}

a {
    text-decoration: none;
}

button:focus {
    outline: none;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Open Sans', sans-serif;

}

.navbar {
    width: 100%;
    display: flex;
    align-items: center;
    position: fixed;
    gap: 40px;
    z-index: 9999;
    padding: 20px 85px;

    transition: 0.2s ease;
    display: flex;
    justify-content: space-between;
}

.nav-spacer {
    width: 100%;
    height: 120px;
}

.navbar.scrolled {
    background-color: white
}

.navbar.scrolled>.nav-left>ul>li>a {
    color: black;
}

.navbar.scrolled>.nav-left>ul>li>div>a {
    color: black;
}

.nav-left {
    display: flex;
    gap: 45px;
    align-items: center;
}


.nav-left>a>img {
    width: 190px;
    object-fit: scale-down;


}

.navigation-dots {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.nav-items {
    display: flex;
    list-style-type: none;
    align-items: center;
    gap: 30px;
}

.nav-items>li>a {
    text-decoration: none;
    color: white;

    font-size: var(--px15);
    font-weight: 600;
}

.nav-items>li>a:hover {
    opacity: 0.7;
}














.dropdown-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    box-shadow: 0 5px 16px 0 rgba(0, 0, 0, 0.126);
    z-index: 1;
    width: fit-content;
    padding: 30px 30px;
    border-radius: 10px;
    gap: 35px;

    opacity: 0;
    transition: 0.6s ease;
    width: fit-content;
    margin: auto;
    ;
    padding-bottom: 23px;

}

.grouper-divider {
    width: 100%;
    height: 1px;
    background-color: #eaeaea;
    margin: 7px 0px
}

.dropdown-content>a:last-of-type {
    color: var(--main-color);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: solid 1px rgb(0 0 0 / 5%) !important;
    padding-top: 9px;
    margin-top: -6px;
}



.dropdown-content>a:last-of-type>i {
    font-size: 30px;
    color: var(--main-color);
}


/* Show dropdown when hovering over hover-dropdown */
.hover-dropdown:hover .dropdown-content {
    display: flex;

    opacity: 1;
    /* Display dropdown on hover */
}

/* Optional: Add a little visual cue that these have dropdowns */
.hover-dropdown>a:after {

    font-size: 0.8em;
}

.hover-dropdown>a:hover {
    opacity: 0.7;
}

.dropdown-grouper {
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.dropdown-grouper>h4 {
    font-size: var(--px14);
    font-weight: 700;

    margin-bottom: 5px;
    display: flex;
    align-items: center;
    gap: 5px;

}

.dropdown-grouper>h4>i {
    font-size: 20px;
    color: var(--main-color);
}

.dropdown-grouper>a {
    color: black;
    text-decoration: none;
    font-size: var(--px14);
    font-weight: 600;

    padding-top: 15px;
    opacity: 0.8;
}

.hover-dropdown>a {
    text-decoration: none;
    color: white;
    font-size: var(--px15);
    font-weight: 600;
    display: flex;
    align-items: center;
    padding-bottom: 25px;
    margin-top: 25px;


}

.dropdown-grouper>a:hover {
    opacity: 1;
}

.dropdown-content>a:last-of-type {
    border: none;
}

.hover-dropdown>a>i {
    font-size: 30px;
    margin-right: -5px;
}


.dropdown-content>a {
    color: black;
    text-decoration: none;
    font-size: var(--px16);
    font-weight: 600;

    padding-bottom: 10px;

}

.dropdown-icon {
    display: flex;

}

.dropdown-icon>i {
    font-size: 30px;
    margin-left: 50px;
    margin-top: -28px;
    color: white;
    margin-bottom: -10px;
}

.dropdown-content>a:hover {
    color: var(--main-color);
}








.hero-text {
    margin-left: 75px;
    margin-top: 20px;
}

.hero-text-wrapper {
    position: absolute;
    top: 0px;
    display: flex;
    align-items: center;
    height: 100vh;
}

.hero-video-overlay {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100vh;
    opacity: 0.85;
    background: linear-gradient(99deg, rgba(0, 0, 0, 0.75) 24.31%, rgba(0, 0, 0, 0.40) 58.5%);
    z-index: 1;
}

.page-hero {
    width: 100%;
}

.hero-video>video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    background-color: black;
    transform: scaleX(-1);
}

.hero-video {
    height: 100vh;
    position: relative;
    z-index: -1;
    ;
}

.hero-text>span {
    font-size: var(--px18);
    font-weight: 700;
    color: var(--main-color);
}

.hero-text>h1 {
    font-size: 55px;
    font-weight: 700;
    color: white;
    font-family: 'Urbanist', sans-serif;
    width: 500px;
    line-height: 60px;
}

.hero-text>h1>span {
    color: var(--main-color);
}

.hero-text>p {
    font-size: var(--px16);
    font-weight: 400;
    color: white;
    line-height: 30px;
    opacity: 0.8;
}

.dark-hero>h1 {
    color: black;
}

.dark-hero>p {
    color: black;
    max-width: 500px;
}

.dark-hero>.button-groups>button:last-of-type,
.dark-hero>.button-groups>a:last-of-type {
    color: black;
    background-color: rgb(242, 242, 242);
}

.dark-hero>.button-groups>button:hover {}

.button-groups {
    display: flex;
    gap: 10px;
    margin-top: 14px;
}

.button-groups>button,
.button-groups>a {
    padding: 15px 40px;
    border-radius: 80px;
    font-size: var(--px17);
    font-weight: 600;
    color: white;
    background-color: var(--main-color);
    border: none;
    cursor: pointer;

}

.button-primary {
    padding: 15px 40px;
    border-radius: 80px;
    font-size: var(--px16);
    font-weight: 600;
    color: white;
    background-color: var(--main-color);
    border: none;
    cursor: pointer;
}

.button-flat {
    padding: 15px 40px;
    border-radius: 80px;
    font-size: var(--px16);
    font-weight: 600;
    color: var(--main-color);
    background-color: white;

    cursor: pointer;
    text-decoration: none;
}

.button-static {
    padding: 15px 40px;
    border-radius: 80px;
    font-size: var(--px16);
    font-weight: 600;
    color: black;
    font-weight: 700;
    background-color: #F8F8F8;
    border: none;
    cursor: pointer;

}

.button-groups>button:hover,
.button-groups>a:hover {
    opacity: 0.7;
}

.button-groups>button:last-of-type,
.button-groups>a:last-of-type {
    background: rgba(255, 255, 255, 0.13);
    color: white;
    backdrop-filter: blur(27px);
    -webkit-backdrop-filter: blur(27px);

}













.split-container {
    display: grid;
    margin: auto;
    width: 90%;
    padding: 100px 0px;
    max-width: 1300px;
    justify-content: space-between;
    grid-template-columns: 1fr 57%;
}

.inline-split-mode {
    padding: 0px;
}

.split-text {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
}

.core-text>span {
    font-size: var(--px20);
    font-weight: 700;
    color: var(--main-color);
}

.core-text>h1 {
    font-size: 40px;
    font-weight: 700;
    color: var(--base-gray);

    width: 500px;
    line-height: 43px;
}

.core-text>p {
    font-size: var(--px16);
    font-weight: 400;
    color: black;
    line-height: 30px;
    opacity: 0.9;
    margin-top: 10px;
    width: 300px
}

.core-list>ul {
    list-style-type: none;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.core-list>ul>li {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: var(--px15);

}

.core-list>ul>li>i {
    font-size: 22px;
    color: var(--main-color);
}

.flyby-before-after {
    border-radius: 25px;
    background: #FFF;
    box-shadow: 0px 13px 57px 1px rgba(0, 0, 0, 0.06);
    padding: 20px;
    display: flex;

}

.seek-btn {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.seek-btn>i {
    font-size: 33px;
    color: var(--main-color);
}

.seek-btn-container {
    height: 100%;
    display: flex;
    align-items: center;
}


.flyby-before-after {
    display: flex;
    align-items: center;
    width: 100%;
}

.flyby-seek-right {

    margin-left: -60px;
    position: relative;
    z-index: 100;
}

.flyby-seek-left {
    margin-right: -60px;
    position: relative;
    z-index: 100;
}

.before-after-slider img {
    border-radius: 10px;
}

.before-after-slider__delimiter {
    width: 7px;
}


.before-after-slider img {
    width: 760px;
    height: 420px;
    object-fit: cover;
}




.dot {
    height: 10px;
    width: 10px;
    margin: 5px;
    display: inline-block;
    border-radius: 50%;
    background-color: rgb(189, 189, 189);
    cursor: pointer;
}

.dot.active {
    background-color: var(--main-color);
}





















.chunker-hero {
    width: 100%;
}

.chunker-hero>img {
    width: 100%;
    height: 500px;
    object-fit: cover;
}

.chunker-content {
    margin: auto;
    border-radius: 30px;
    background: #FFF;
    box-shadow: 0px 13px 57px 1px rgba(0, 0, 0, 0.06);
    position: relative;
    z-index: 100;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 320px;
    margin-top: -170px;
    width: 90%;
    max-width: 1300px;
}

.chunklet>span {
    font-size: 65px;
    font-weight: 600;
    color: var(--main-color);
    line-height: 65px;
}

.chunklet {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.chunklet-divider {
    width: 1px;
    height: 80%;
    background-color: #d8d8d8;
}
















.service-content {
    display: grid;
    grid-template-columns: 1fr 400px;
    width: 100%;
    padding: 75px;
    gap: 50px;
}

.core-header {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}

.inline-header-core {
    height: 20vh !important;
    min-height: 260px !important;
}

.core-header>span {
    font-size: var(--px20);
    font-weight: 700;
    color: var(--main-color);
}

.core-header>h1 {
    font-size: 40px;
    font-weight: 700;
    color: var(--base-gray);


    line-height: 43px;
}

.core-header>p {
    font-size: var(--px15);
    font-weight: 400;
    color: black;
    line-height: 25px;
    opacity: 0.9;
    width: 410px;
    text-align: center;
    margin-top: 5px;

}

.inline-header-core>h1 {
    font-size: 50px;
    text-align: center;
    font-family: 'Urbanist', sans-serif;
    line-height: 55px;
}

.title-header-core {
    height: '30vh !important';
    min-height: 400px !important;
}

.title-header-core>h1 {
    font-size: 50px;
    text-align: center;
    font-family: 'Urbanist', sans-serif;
    line-height: 55px;
}

.service-cards {
    display: flex;
    gap: 25px;
    margin: auto;
    width: 90%;
    max-width: 1300px;

}

.service-cards-list {
    display: flex;
    gap: 33px;
    margin: auto;
    width: 90%;
    max-width: 1300px;
    margin-top: 75px;
    justify-content: center;
}

.service-card {
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 13px 57px 1px rgba(0, 0, 0, 0.06);
    display: flex;
    flex-direction: column;
    transition: 0.2s ease;
    height: fit-content;
    min-height: 420px;
    width: 33.3%;
    text-decoration: none;
    color: black;
}

.service-card>img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 20px 20px 0px 0px;
}

.service-card>h2 {
    margin: 0px 30px;
    font-size: 25px;
    font-weight: 700;
    color: var(--base-gray);
    margin-top: 25px;
    line-height: 33px;


}


.service-card>p {
    margin: 0px 30px;
    font-size: var(--px13);
    font-weight: 400;
    line-height: 22px;
    opacity: 0.8;
    margin-top: 2px;
}

.service-action-card {
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 13px 57px 1px rgba(0, 0, 0, 0.06);
    display: flex;
    flex-direction: column;
    transition: 0.2s ease;
    height: fit-content;
    min-height: 420px;
    width: 40%;
    justify-content: space-between;
}

.service-action-header {
    margin-top: 30px;
}

.service-action-header>h2 {
    margin: 0px 30px;
    font-size: 25px;
    font-weight: 700;
    color: var(--base-gray);
}

.service-container {
    padding: 120px 0px;
    display: flex;
    flex-direction: column;
    gap: 35px;
}

.service-action-header>ul>li::marker {
    color: var(--main-color);
    font-size: 20px;
}

.service-action-header>ul>li>a {
    text-decoration: none;
    color: var(--base-gray);
    font-size: var(--px15);
    font-weight: 400;
    line-height: 22px;
    opacity: 0.8;
    margin-top: 2px;
}

.service-action-header>ul>li>a:hover {
    opacity: 1;
    color: var(--main-color);
}

.service-action-header>ul {
    margin: 0px 50px;
    margin-top: 15px;
}

.service-action-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 25px;
    gap: 0px;
}






















.reviews-container {
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 120px;
    gap: 42px;
}

.simple-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: auto;
    width: 90%;
    max-width: 1300px;
    gap: 25px;
    margin-top: 0px;
    margin-bottom: 0px;

}

.simple-cards>a {
    text-decoration: none;
    color: black;
}

.simple-card {
    border-radius: 20px;
    background: #FFF;
    box-shadow: 0px 13px 57px 1px rgba(0, 0, 0, 0.06);
    display: flex;
    flex-direction: column;
    transition: 0.2s ease;
    height: 220px;
    justify-content: center;
    padding: 35px;

}

.simple-card>h3 {
    font-size: 25px;
    font-weight: 700;
    color: var(--base-gray);
}

.simple-card>p {
    font-size: var(--px14);
    font-weight: 400;
    line-height: 22px;
    opacity: 0.8;
    margin-top: 2px;
}

.star-rating>i {
    font-size: 23px;
    color: var(--main-color);
}

.google-reviews>img {
    width: 114px;
}









.mantine-Accordion-item:where([data-active]) {
    background-color: rgb(251, 251, 251) !important;
}

.mantine-Accordion-root {
    margin: auto;
    width: 90%;
    max-width: 950px;
    box-shadow: 0px 3px 57px 1px rgba(0, 0, 0, 0.06);
    border-radius: 30px;
}

.mantine-Accordion-control:hover {
    background-color: rgb(251, 251, 251) !important;
}


.mantine-Accordion-label {
    font-size: 17px;
    height: 100px;
    color: black !important;
    display: flex;
    align-items: center;
    font-weight: 600 !important;
}

.mantine-Accordion-control {
    padding: 0px 35px !important;
}

.mantine-Accordion-item {
    border-left: none !important;
    border-right: none !important;
}

.mantine-Accordion-item:first-of-type,
.mantine-Accordion-item:first-of-type>[data-accordion-control] {
    border-top-left-radius: 30px !important;
    border-top-right-radius: 30px !important;
    border-top: none !important;
}

.mantine-Accordion-item:last-of-type,
.mantine-Accordion-item:last-of-type>[data-accordion-control] {
    border-bottom-left-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
    border-bottom: none !important;

}

.accordion-container {
    display: flex;
    flex-direction: column;
    gap: 50px;

}

















.group-cards {
    display: flex;
    margin: auto;
    width: 90%;
    background-color: white;
    box-shadow: 0px 13px 57px 1px rgba(0, 0, 0, 0.06);
    border-radius: 30px;
    padding: 50px;

}

.group-card {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 35px;
    gap: 15px;
}

.group-card-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.group-card-text>h3 {
    font-size: 25px;
    font-weight: 700;
    color: var(--base-gray);
    text-align: center;
    line-height: 30px;
}

.group-card-text>p {
    font-size: var(--px14);
    font-weight: 400;
    line-height: 22px;
    opacity: 0.8;
    margin-top: 2px;
    text-align: center;
}

.group-card>img {
    width: 100px;
    margin: auto;
}

.contact-container {
    display: flex;
    flex-direction: column;
    gap: 50px;
    margin: 120px 0px;

}

.group-card-text>span {
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    color: var(--main-color);
    width: 100%;
    display: block;
}














.footer {
    margin: auto;
    width: 90%;

}

.footer-content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px
}

.footer-links {
    display: flex;
    gap: 50px;
}

.footer-link-group>ul>li>a {
    text-decoration: none;
    color: var(--base-gray);
    font-size: var(--px15);
    font-weight: 500;
    line-height: 22px;
    margin-top: 2px;
}

.footer-logo>img {
    width: 140px;
    margin-bottom: 30px;
}

.footer-logo {
    display: flex;
    flex-direction: column;
    width: 500px;
}

.footer-logo>a {
    text-decoration: none;
    color: black;
    ;
    font-weight: 600;
    font-size: var(--px14);
}

.footer-logo>p {
    font-size: var(--px14);
    font-weight: 400;
    line-height: 22px;
    color: var(--base-gray);
}



.footer-link-group>ul {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.footer-bottom {
    display: flex;
    justify-content: space-between;
}

.footer-bottom>ul {
    display: flex;
    list-style-type: none;
    gap: 20px;
}

.footer-bottom>ul>li>a {
    text-decoration: none;
    color: var(--base-gray);
    font-size: var(--px14);
    font-weight: 400;
    line-height: 22px;
    margin-top: 2px;
}

.footer-link-group>ul>li>a:hover {
    opacity: 0.7;
    text-decoration: underline;
}

.footer-bottom {
    height: 65px;
    display: flex;
    align-items: center;
}

.footer-bottom>p {
    font-size: var(--px14);
    font-weight: 400;
    line-height: 22px;
    color: var(--base-gray);
}

.footer-link-group>h4 {
    font-size: var(--px15);
    font-weight: 700;
    color: var(--base-gray);
    margin-bottom: 5px;
}


.footer-link-group>ul>li>span {
    font-size: var(--px15);
    font-weight: 400;
    line-height: 22px;
    color: var(--base-gray);
    margin-top: 2px;
}

.mobile-btn {
    display: none;
}


















.mobile-menu {
    display: none;
}

.short-hero {
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 400px;
}

.short-hero>img {
    width: 100%;
    height: 60vh;
    object-fit: cover;

    filter: brightness(0.6);
    position: absolute;
    min-height: 400px;
}

.short-hero>video {
    width: 100%;
    height: 60vh;
    object-fit: cover;
    filter: brightness(0.6);
    position: absolute;
    min-height: 400px;
    object-position: bottom;
}

.short-hero>h1 {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Urbanist', sans-serif;
    font-size: 55px;
    color: white;
}

.floating-text {
    margin: auto;
    max-width: 630px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    min-height: 290px;
    height: 40vh;
    justify-content: center;
    padding: 0px 20px;

}

.floating-text>p {
    width: 100%;
    text-align: center;
    font-size: var(--px16);
    line-height: 30px;

}

.floating-bar {
    width: 150px;
    height: 5px;
    background-color: var(--main-color);

}

.tri-mode {
    height: 600px;
    margin-bottom: 0px !important;
}

.duo-cards {
    display: flex;
    margin: auto;
    width: 90%;
    max-width: 1300px;
    gap: 55px;
    justify-content: center;
    margin-bottom: 120px;
}

.duo-wide {
    width: 100%;
    margin-top: 30px;
    gap: 30px;

}

.depth-card {
    width: 50% !important;
    background-color: white;
    box-shadow: 0px 13px 57px 1px rgba(0, 0, 0, 0.06);
    border-radius: 30px;
    padding: 50px;


}

.social-strip {
    display: flex;
    gap: 10px;
    margin-top: 30px;
}

.social-strip>a>img {
    width: 52px;
    height: 52px;
    object-fit: cover;
    border-radius: 50%;
    transition: 0.2s ease;
}

.social-strip>a>img:hover {
    transform: scale(1.1);
}

.duo-card {
    width: 380px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.duo-card>img {
    width: 129px;
    height: 129px;
    ;
    object-fit: scale-down
}

.duo-card>h1 {
    font-size: 25px;
    font-weight: 700;
    color: var(--base-gray);
    margin-top: 25px;
    line-height: 33px;
    text-align: center;
}

.duo-card>p {
    font-size: var(--px15);
    font-weight: 400;
    line-height: 22px;
    opacity: 0.8;
    margin-top: 2px;
    text-align: center;
}

.zigzag {
    display: flex;
    flex-direction: column;
}

.zigzag-card {

    display: flex;
    width: 100%;
}

.zigzag-card>img {
    width: 50%
}

.zigzag-card>video {
    width: 50%;
    min-height: 600px;
    object-fit: cover;
    max-height: 730px;
}

.zigzag-text {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.zigzag-text>p {
    text-align: center;
    width: 400px;
}

.zigzag-text>h1 {
    font-size: 25px;
    font-weight: 700;
    color: var(--base-gray);
    margin-top: 10px;

}

.zigzag-text>img {
    width: 129px;
    height: 129px;
    object-fit: scale-down
}

.zigzag-text>span {
    font-size: 65px;
    font-weight: 700;
    color: var(--main-color);
    line-height: 50px;
}

.zigzag-card:nth-child(odd) {
    flex-direction: row-reverse;
}

.sec-hero {
    display: flex;
    height: 100vh;
    align-items: center;

    gap: 110px;
    justify-content: center;
    margin: auto;
    max-width: 1600px;
    margin-top: -120px;

}

.sec-banner {
    height: 100%;
    width: 50%;
}

.sec-banner>img {
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.sec-banner>video {
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.modal-window-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.308);
    z-index: 999999;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(30px);
}



.modal-overlay-close {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    border: none;
    background-color: transparent;
}

.modal-window {
    background-color: white;
    border-radius: 30px;
    padding: 9px 52px;
    width: 90%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-height: 94%;
    overflow-y: scroll;
}

.modal-close {
    padding: 13px;
    border: none;
    background-color: transparent;
    border-radius: 50px;
}

.modal-close>i {
    font-size: 30px;
    color: black;

}

.modal-close-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: -85px;
    margin-top: 15px;
    margin-right: -28px;
}

.modal-close {
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-header>h1 {
    font-size: 30px;
    font-weight: 700;
    color: var(--base-gray);

}

.status-tabs {
    display: flex;
    gap: 23px;
    border-bottom: solid 1px #eaeaea;
}

.status-tab {
    font-size: 17px;
    font-weight: 600;
    color: var(--base-gray);
    height: 45px;
    border-bottom: solid 3.5px transparent;
    transition: 0.2s ease;

}

.modal-header {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-top: 30px;
}

.active-tab {
    color: var(--main-color);
    font-weight: 700;
    border-bottom: solid 3.5px var(--main-color);
}

.flyby-input {
    display: flex;
    flex-direction: column;
    gap: 10px;

}

.flyby-input>select {
    padding: 14px 20px;
    border-radius: 10px;
    background-color: #f8f8f8;
    border: none;
    font-size: 16px;
    font-weight: 600;
    color: black;
}

.modal-strip {
    display: flex;
    margin: auto;
    width: 100%;
    margin-bottom: -50px;
    justify-content: flex-end;
}

.flyby-input>select:focus {
    outline: solid 3px var(--main-color);
}

.form-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.flyby-form {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 40px;
    margin-top: 10px;
}

.flyby-input>label {
    font-size: 16px;
    font-weight: 600;
    color: var(--base-gray);
    display: block;
}

.flyby-input>input {
    padding: 14px 20px;
    border-radius: 10px;
    background-color: #f8f8f8;
    border: none;
    font-size: 16px;
    font-weight: 600;
    color: black;
}

.flyby-form-group {
    display: grid;
    gap: 4%;
    grid-template-columns: 48% 48%;
}

.flyby-input>input::placeholder {
    color: #686868;
    font-weight: 600;
}

.flyby-input>input:focus {
    outline: solid 3px var(--main-color);
}

.bulk-btn {
    padding: 15px 40px;
    border-radius: 16px;
    font-size: var(--px17);
    font-weight: 600;
    color: white;
    background-color: var(--main-color);
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    margin-top: 10px;
}

.disabled-btn {
    background-color: #d8d8d8;
    cursor: not-allowed;
    color: gray;
}

.bulk-btn-minimal {
    padding: 7px 20px;
    border-radius: 16px;
    font-size: var(--px17);
    font-weight: 600;
    color: black;
    background-color: #f8f8f8;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    margin-top: 10px;
}

.button-grouper {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 15px;
}

.bulk-btn>span {
    font-size: 16px;
    font-weight: 600;
    color: white;
}

.text-block {
    margin: auto;
    width: 70%;
    max-width: 1100px;
    margin-bottom: 100px;

}

.text-block>h2 {
    font-size: 20px;
    font-weight: 700;
    color: var(--base-gray);
    margin-top: 25px;
    line-height: 33px;
}

.text-block>ul {
    padding-left: 15px;
}

.text-block>h1 {
    font-size: 30px;
    font-weight: 700;
    color: var(--base-gray);
    margin-top: 25px;
    line-height: 33px;
    margin-bottom: 10px;
}

.text-block>h3 {
    font-size: 16px;
    font-weight: 700;
    color: var(--base-gray);
    margin-top: 25px;
    line-height: 33px;
    margin-bottom: 10px;
}

.nav-logo {
    display: block;
}

.nav-logo-mobile {
    display: none;
}

/* media query at 768px */
@media screen and (max-width: 1130px) {

    .modal-window {
        max-width: none;
        width: 100%;
        border-radius: 0px;
        max-height: none;
        height: 100vh;
        padding: 9px 26px;
    }

    .modal-header>h1 {
        font-size: 27px;
    }

    .modal-close-container {
        margin-bottom: -82px;
        margin-top: 3px;
        margin-right: -17px;
        position: relative;
        z-index: 159;
    }


    .flyby-input>input::placeholder {
        color: #686868;
        font-weight: 600;
        font-size: 14px;
    }

    .flyby-input>select {
        height: 55px;
    }

    .flyby-input>input {
        font-size: 16px;
    }

    .modal-header {
        padding-top: 60px;
    }

    .status-tab {
        font-size: 15px;
    }

    .modal-content {
        max-width: 500px;
    }

    .desktop-menu {
        display: none !important;
    }

    .mobile-btn {
        display: block;
        position: fixed;
        right: 25px;
        top: 25px;
        z-index: 99999
    }

    .hero-video {
        height: 50vh;
        z-index: 100
    }

    .hero-video-overlay {
        display: none;
    }

    .mobile-menu {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 99999;

        background-color: var(--main-color);
        width: 100%;
        height: 100%;
        overflow-y: scroll;
    }

    .nav-items {
        display: flex;
        flex-direction: column;
        gap: 15px;
        align-items: flex-start;
    }

    .sec-banner>img {
        max-height: 300px;
    }

    .nav-items>li>a {
        font-size: var(--px18);
        font-weight: 600;
        color: white;

        width: 100%;
        display: block;
        padding-left: 20px;
    }

    .nav-items>li {
        width: 100%;
        border-bottom: solid 1px rgba(255, 255, 255, 0.1);
        padding-bottom: 15px;
    }

    .hover-dropdown>a {
        margin-top: 0px;
        padding-bottom: 0px;
        font-size: var(--px18);
        font-weight: 600;
        padding-left: 20px;
    }

    .ham-btn {
        background-color: white;
        border: none;
        width: 46px;
        height: 46px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 35px;
        box-shadow: 0px 13px 57px 1px rgba(0, 0, 0, 0.3);
        padding: 25px;


    }

    .nav-left>a>img {
        height: 65px;
        width: 180px;

    }

    .nav-logo-mobile {
        display: block;
    }

    .nav-logo {
        display: none;
    }

    .ham-btn>i {
        color: var(--main-color);
        font-size: 27px;
    }

    .page-hero {
        display: flex;
        flex-direction: column-reverse;
        margin-top: 100px;
    }

    .hero-text-wrapper {
        position: static;
        height: fit-content;
        padding: 20px 20px;
        margin-bottom: 15px;
    }

    .navbar {
        padding: 20px 35px;
        justify-content: center;
        background-color: white;
        position: static;
        top: 0px;
    }

    .nav-spacer {
        display: none;
    }

    .active-btn {
        background-color: #3178b7;
        ;
    }

    .active-btn>i {
        color: white;
    }

    .nav-items {
        margin-top: 15px;
    }

    .sec-hero-text {
        margin: 30px;
    }

    .core-text>h1 {
        width: 100%;
    }

    /*  footer {
        display: none;
    }
    .page-hero {
        display: none;
    }
 */

    .chunklet {
        height: 240px;
    }

    .before-after-slider-container {
        margin-top: 40px;
    }

    .contact-container {
        margin: 50px 0px;
        margin-top: 30px
    }

    .chunklet-divider {
        width: 90%;
        height: 1px;
        margin: auto;
        opacity: 0.5;
        ;
    }

    .core-header {
        padding: 0px 20px;
    }

    .core-header>h1 {
        font-size: 30px;
        text-align: center;
        line-height: 33px;

    }

    .inline-header-core {

        min-height: 170px !important;
    }

    .dropdown-content {
        gap: 14px;
        opacity: 0;
        transition: 0.6s ease;
        flex-direction: column;

        padding-top: 23px;
        position: static;
        display: flex !important;
        opacity: 1 !important;
        width: 90%;
    }

    .sec-hero {
        flex-direction: column-reverse;
        margin-top: 0px;
        height: fit-content;
        gap: 0px;
    }

    .sec-banner {
        width: 100%;
    }

    .sec-banner>video {
        max-height: 360px;
    }

    .duo-card {
        width: 100%;
    }

    .duo-cards {
        flex-direction: column;
        width: 100%;
        margin-top: 160px;

        margin-bottom: 160px !important;
        margin: 200px 0px;
        padding: 31px;

    }



    .zigzag-card {
        flex-direction: column !important;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;
    }

    .zigzag {
        margin-top: 50px !important;
    }

    .zigzag-text {
        width: 100%;
        padding: 30px;
    }

    .zigzag-text>h1 {
        text-align: center;
    }

    .zigzag-text>p {
        width: 100%;
        text-align: center;
    }

    .zigzag-card>img {
        width: 90%;

        object-fit: cover;
        margin: auto;
        border-radius: 20px;
        box-shadow: 0px 13px 57px 1px rgba(0, 0, 0, 0.06);

    }

    .zigzag-card>video {
        width: 90%;

        object-fit: cover;
        margin: auto;
        border-radius: 20px;
        box-shadow: 0px 13px 57px 1px rgba(0, 0, 0, 0.06);

    }


    .button {
        padding: 18px 27px !important;
        text-align: center;
        font-size: 15px !important;
    }

    .flyby-before-after {
        padding: 0px;
    }

    .service-card {
        width: 100%;
    }

    .service-action-card {
        width: 100%;
    }

    .footer-logo>img {
        display: none;
    }

    .footer-links {
        flex-direction: column;
        margin-top: 35px;

    }

    .footer-content {
        flex-direction: column;
    }

    .footer-logo {
        width: 100%;
        align-items: center;
    }

    .hero-text {
        margin: 0px
    }

    .hero-text>h1 {
        color: black;
        width: 100%;
        font-size: 40px;
        line-height: 45px;
    }

    .hero-text>p {
        color: black;
        width: 100%;
        font-size: 15px;
        line-height: 30px;
    }


    .dropdown-grouper>a {
        color: black;
        text-decoration: none;
        font-size: var(--px16);
        font-weight: 600;
        padding-top: 23px;
        opacity: 0.8;
    }

    .button-groups>button,
    .button-groups>a {
        padding: 15px 40px;
        border-radius: 80px;
        font-size: var(--px14);
        font-weight: 700;
        color: white;
        background-color: var(--main-color);
        border: none;
        cursor: pointer;
        width: 100%;
    }

    .page-hero {
        margin: 0px;
    }

    .button-groups>button:last-of-type,
    .button-groups>a:last-of-type {
        color: black;
        background-color: transparent;
        border: 1px solid rgba(0, 0, 0, 0.093);
    }

    .split-container {
        grid-template-rows: none;
        grid-template-columns: 1fr;
        padding: 20px 0px
    }

    .duo-wide {

        margin: 10px 0px;
        padding: 21px;
        margin-top: 10px;
        margin-bottom: 50px !important;
    }

    .depth-card {
        width: 100% !important;

    }

    .footer-content {
        margin-bottom: 40px;
    }

    .footer-bottom {
        flex-direction: column;
        height: auto;
        margin-bottom: 40px;
    }



    .simple-cards {
        display: flex;
        flex-direction: column;
    }

    .chunker-content {
        display: flex;
        flex-direction: column;
        height: fit-content !important;
    }

    .service-card {
        padding-bottom: 30px;
    }

    .dropdown-content {
        margin-top: 15px;
        border-radius: 15px;

    }

    .service-cards {
        display: flex;
        flex-direction: column;

    }

    .service-cards-list {
        display: flex;
        flex-direction: column;
    }

    .core-header {
        padding-top: 40px !important;
    }

    .core-header>p {
        width: 100%;
        padding: 0px 30px
    }

}